import React, { useState, useEffect } from 'react';
import {FaTrash} from 'react-icons/fa';
import styled from 'styled-components';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../../aws-exports';
import {tableRows} from '../../constants/TableRows';
import Off from '../../assets/ToggleOff.svg'
import {Link, navigate} from "gatsby";
import On from '../../assets/ToggleOn.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaCaretDown,FaInfoCircle } from "react-icons/fa";
import { faEdit,faDownload,faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import {getTemplatesByClientID,shareTemplate,removeTemplate} from '../../graphql/queries';
import { useSelector, useDispatch } from "react-redux";
import {logos} from '../../constants/Logos';
import Select, { components } from "react-select";
import Loader from './../../assets/loading.gif';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Sync from "../../assets/sync.gif";
Amplify.configure(config);
const steps = logos[0].steps;

const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </components.DropdownIndicator>
    );
};

const Placeholder = props => {
    return <components.Placeholder {...props} />;
};

export default function WorkOrderTemplateTable(props) {
    const {consoleLog} = require('../commonFunctions.js');
    const [templates, setTemplates] = useState([]);
    const dispatch = useDispatch();

    const [templateInfo, setDocType] = React.useState(useSelector((state) => state.templateInfo));
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));

    const [synching, setSynching] = useState(false);
    const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
    };
    // const [workOrderTemplate, setWorkOrderTemplate] = React.useState([]);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     fetchTemplates();
    // }, templates);

    // async function fetchTemplates() {
    //     consoleLog("going to fetch work order templates dataaaa")
    //     let client_id = JSON.parse(localStorage.getItem('client_data')).client_id;
    //     consoleLog("client_id: ", client_id)

    //     const work_order_templates = await API.graphql({
    //         query: getTemplatesByClientID,
    //         variables: {
    //             user_id: client_id,
    //             doc_type_id: 2
    //         }
    //     })

    //     let wo_templates = work_order_templates.data.getTemplatesByClientID;
    //     consoleLog("work_order_templates: ", wo_templates);
    //     setTemplates(wo_templates);

    //     let templateArray=[];
    //     for (var i=0; i < wo_templates.length;i++) {
    //         let templateObj={};
    //         templateObj.value = wo_templates[i].template_id;
    //         templateObj.label = wo_templates[i].name;
    //         templateArray.push(templateObj);
    //     }
    //     setWorkOrderTemplate(templateArray);
    //     setLoading(false);
    // }

    async function updateShared(template_id,shared) {
        showSynching();
        const sharedUpdate = await API.graphql({
            query: shareTemplate,
            variables: {
                template_id: template_id,
                shared:shared
            }
        })
    }
    async function deleteTemplate(template_id) {
        showSynching();
        const deleteTemp = await API.graphql({
            query: removeTemplate,
            variables: {
                template_id: template_id
            }
        })
        props.fetchTemplates();
    }

    const trows = tableRows[0].rows;
    const [toggle, setToggle] = React.useState(true);
    function handleClick (e,shared,template) {
        if(shared)
        {
            shared=0;
            updateShared(template,shared);
            e.target.src=Off;
        }
        else{
            shared=1;
            updateShared(template,shared);
            e.target.src=On;
        }
        // fetchTemplates();
    };
    function deleteClick(template_id)  {
        alert(template_id);
        deleteTemplate(template_id);
    }
    function downloadClick(content)  {
        let fileName="json-file-name.json";
        let contentType="text/plain";
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    function handleEditWorkOrderTemplate(template_id){
        consoleLog("template_id: ", template_id)

        for(let i=0;i< templates.length; i++){
            if(templates[i].template_id == template_id){
                consoleLog("selected template: " , templates[i]);
                navigate('/template?template_id='+template_id);
            }
        }
    }
    const [isActive, setActive] = useState(new Array(props.templatex.length).fill(false));
    const ToggleClass = (index) => {
        const vals = new Array(props.templatex.length).fill(false);
        vals[index] = !isActive[index];
        setActive(vals);
    };

    consoleLog("props.wrkTemplate>", props.wrkTemplate);

    //pagination
    const [itemsPerPage, setItemsPerPage] = useState(20);
    // const [pageDocuments, setPageDocuments] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(0);

    function handlePageChange(data){
        const newOffset = (data.selected * itemsPerPage) % props.templatex.length;
        consoleLog(
          `User requested page number ${data.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    }

    useEffect(() => {
        setEndOffset(itemOffset + itemsPerPage);
        // setPageDocuments(props.templates.slice(itemOffset, endOffset));
        // console.log(`Loading Estimate items from ${itemOffset} to ${endOffset}, pages ${Math.ceil(props.templatex.length/itemsPerPage)},  size ${props.templatex.length}`);
        setPageCount(Math.ceil(props.templatex.length/itemsPerPage));
    }, [itemOffset, itemsPerPage, props.templatex]);
    return (
        <>

            <TabTable>
                <p className="tTitle"> My Work Order Templates </p>
                <FlexBidsBox>
                    { props.loading ?
                        <LoadingContainer>
                            <img className="loader" src={Loader}/>
                        </LoadingContainer>

                    :
                    <MainBidsPageContent>
                        <TableBox>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-left">Name</th>
                                            <th scope="col" className="text-center">Modified</th>

                                            <th scope="col" className="text-center">Share</th>
                                            <th scope="col" ></th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                        {props.templatex.slice(itemOffset, endOffset).map((row, i) => (
                                            // <tr key={row.template_id}>
                                            //     <td align="center">
                                            //     <FontAwesomeIcon icon={faTrashAlt} onClick={(e)=>deleteClick(row.template_id)} style={{cursor:"pointer"}}/>
                                            //     </td>
                                            //     <td align="center" className="text-left">{row.name}</td>
                                            //     <td align="center"><FontAwesomeIcon  icon={faDownload} onClick={()=>downloadClick(row.data)} /></td>
                                            //     <td align="center">{row.date_updated}</td>
                                            //     {/* <td align="center"><Link className="w-100 noDec" to="/newTemplate"><FontAwesomeIcon icon={faEdit}  /></Link></td> */}
                                            //     <td align="center">
                                            //         {/* <button className="w-100 noDec" onClick={(e)=>handleEditWorkOrderTemplate(row.template_id)}>
                                            //             <FontAwesomeIcon icon={faEdit}  />
                                            //         </button> */}
                                            //         <Link to={`/template?template_id=${row.template_id}`}>
                                            //             <FontAwesomeIcon icon={faEdit}  />
                                            //         </Link>
                                            //     </td>
                                            //     <td align="center"><img style={{width:"65px"}} src={row.shared ? On : Off} onClick={(e)=>handleClick(e,row.shared,row.template_id)} alt=""/></td>
                                            // </tr>

                                            <tr key={row.template_id} className="custom-tr-temp">
                                                {/* <td align="center">
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </td> */}
                                                <td align="center" className="text-left">{row.name}</td>
                                                {/* <td align="center"><FontAwesomeIcon style={{cursor:"pointer"}} icon={faDownload}  /></td> */}
                                                <td align="center">{moment(row.date_updated).format('MMMM Do YYYY')}</td>
                                                <td align="center"><img style={{width:"65px", cursor:"pointer"}} src={row.shared ? On : Off} onClick={(e)=>handleClick(e,row.shared,row.template_id)} alt=""/></td>
                                                <td align="center">
                                                    <Menu menuButton={  <button className={isActive[i] ? "box-m-btn-active" : "box-m-btn" } onClick={() => ToggleClass(i)}>...</button> }>
                                                    <MenuItem>
                                                    <Link className="custom-li-font w-100" to={`/template?template_id=${row.template_id}`}>
                                                            Edit
                                                        </Link>
                                                    </MenuItem>
                                                        <MenuItem>
                                                            <a className="custom-li-font w-100" onClick={()=>downloadClick(row.data)}>  Download</a>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <a className="custom-li-font w-100" onClick={ (e) => { if (window.confirm('Are you sure you wish to delete this template?')) deleteClick(row.template_id) }} style={{cursor:"pointer"}}>Delete</a>
                                                        </MenuItem>
                                                    </Menu>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </TableBox>
                    </MainBidsPageContent>
                    }
                </FlexBidsBox>
                <div>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            </TabTable>
            <div className="third_left_template">
                <p className="title">Assign Default Estimate Templates <span>
                    <small style={{marginLeft:'5px', marginTop:'5px'}} ><FaInfoCircle size={15} style={{color:'#F7941C'}}/></small>
                    </span> </p>
                <p className="mb-0 desc">Assign your template(s) to a report type by clicking on an edit icon.</p>
            </div>
            <TabIcons>
                {steps.map(step=>(
                    <div className="TabBox">
                        <img src={step.icon} alt=""/>

                        <div className="w-100">
                            <label>Assign your Template</label>
                            <Select
                                closeMenuOnSelect={false}
                                components={{ Placeholder, DropdownIndicator }}
                                placeholder={"Choose"}
                                styles={{
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: "1em",
                                        color: '#4f4f4f',
                                        fontWeight: 400
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused && "#F7F9FA",
                                        color: state.isFocused && "#737F82",
                                        borderBottom:"1px solid #B4BEBF",
                                     })
                                }}
                                options={props.wrkTemplate}
                            />
                        </div>

                    </div>
                ))}
            </TabIcons>
            {synching && <img className="sync" src={Sync} />}
        </>
    );
}

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`
const FlexBidsBox = styled.div`
    width:100%;
    display:flex;
`
const MainBidsPageContent = styled.div`
    width:100%;
    padding:10px 0px;
    display:flex;
    flex-direction:column;

    @media(max-width:600px){
        padding:10px;
        width:100%;
    }
`
const TableBox = styled.div`
    width:100%;
    min-height:100px;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    flex-direction:column;
    margin-bottom:30px;


    .custom-li-font{
        text-decoration:none;
        font-family:Roboto;
        color: #737F82 !important;
    }

    .box-m-btn{
       height:36px;
        width:36px;
        border-Radius:3px;
        outline:none;
         background:#D6E1E5;
          display:flex;
           font-weight:600;

            font-size:18px;
             justify-content:center;
              border:none;
              text-align:center;


  }
  .box-m-btn-active{
       height:36px;
        width:36px;
        border-Radius:3px;
        outline:none;
         background:#F7941C;
          display:flex;
           font-weight:600;

            font-size:18px;
             justify-content:center;
              border:none;
              text-align:center;

  }

    .background{
        background:transparent !important;
    }
    .table{
        .szh-menu__item:active{
            background:#F7941C !important;
        }
            .szh-menu{
                left: -124px !important;
                top: -35px !important;
                text-align: left !important;
            }
            .box-m-btn{
            &:active{
                background-color:#F7941C !important;
            }
        }
        thead{
            border-bottom:2px solid #c2c2c2;
        tr{

            height: 41px !important;
            background: rgb(214, 225, 229) !important;
            border-radius: 6px 6px 0px 0px !important;

            th{
                /* font-weight:500 !important;
                border:none !important;
                color:black;
                padding:0 10px;
                font-size:14px;
                text-align:center; */
                height: 41px!important;

                color: rgb(33, 46, 51)!important;
                font-family: Rajdhani!important;
                text-transform: uppercase !important;
                font-size: 13px !important;
                font-weight: 600 !important;
                letter-spacing: 0px !important;
                line-height: 22px !important;


            }
        }
    }



    .noDec{
        border: none;
        background: none;
    }
    .noDec:focus{
        border: none;
        background: none;
    }

    tbody{

        tr{
            height:41px !important;
            padding:15px 0 !important;
            td{
                font-size:14px !important;
                color: rgb(51, 51, 51) !important;
                font-family:Roboto !important;
            }
        }
    }

}

`
const TabIcons = styled.div`
    min-height:200px;
    width:100%;
    display:flex;
    align-items:center;

    flex-wrap:wrap;
    .TabBox{
        width:245px;
        height:165px;
        background-color:#fff;
        margin: 0px 10px 10px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding:15px 10px;
        border-radius:6px;
    }
    img{
        width:106px;
        height:auto;
        margin:0 5px;
    &:nth-child(4){
        padding-top:10px;
    }
    }
    @media(max-width:600px){
        padding:10px 10px 30px 10px;
    }
    .imgDiv{
        width:147px;
    }
    .image{
        height:70px;
    }
`
const TabTable = styled.div`
    min-height:100px;
    width:100%;

    .tTitle{
        font-size:24px;
        font-family:Roboto;
        text-decoration:initial;
        padding:70px 0 23px 0;
    }
`
