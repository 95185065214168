import React , { useState,useEffect }from 'react'
import Footer from '../Components/Footer/Footer'
import Menu from '../Components/Navbar/Navbar'
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select,{components} from 'react-select';
import Alert from 'react-bootstrap/Alert';
import {Button} from 'react-bootstrap';
import { FaCaretDown,FaInfoCircle } from "react-icons/fa";
import EstimateTemplateTable from '../Components/DocsTable/EstimateTemplateTable';
import WorkOrderTemplateTable from '../Components/DocsTable/WorkOrderTemplateTable';
import InterchangeableText from '../constants/InterchangeableText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import config from '../aws-exports';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { getAssignDefaultTemplates, uploadTemplate, getTemplatesByClientID, getTemplatesByClientIDx, getTemplatesByTeamID, getEstimateTemplates, getWOTemplates} from '../graphql/queries';
import * as queryString from "query-string";
import {Link, navigate} from 'gatsby';
import Modal from 'react-bootstrap/Modal';
import Loader from './../assets/loading.gif';
import ToastBox from '../Components/Toast/Toast';
Amplify.configure(config);

const CaretDownIcon = () => {
    return <FaCaretDown color={'#000'} />;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

export default function Templates(props) {
    console.log("Templates props: ", props);
    const url_params = queryString.parse(props.location.search);
    console.log("Templates url_params: ", url_params );
    console.log("Templates url_params.doc_type_id : ", url_params.doc_type_id )
    const [templates, setTemplates] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    
    const [selectedValue, setSelectedValue] = useState(url_params.doc_type_id);
    const [pageOffset, setPageOffset] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [files, setFiles] = useState("");
    const [show, setShow] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [aDTemplates, setaDTemplates] = useState([]);

    const [teamRole, setTeamRole] = useState('');
    const [userID, setUserID] = useState(0);

    async function fetchTemplates() {
        console.log("fetchTemplates");
        let client_id = JSON.parse(localStorage.getItem('client_data')).client_id;
        let team_role = JSON.parse(localStorage.getItem('client_data')).team_role;
        console.log("fetchTemplates team", team_role);
        let team_id = JSON.parse(localStorage.getItem('client_data')).team_id;
        if(team_role){
            setTeamRole(team_role);
            setUserID(client_id);
        }
        
        console.log("fetchTemplates client_id: ", client_id);
        console.log("fetchTemplates selectedValue: ", selectedValue);
        console.log("fetchTemplates itemsPerPage: ", itemsPerPage);
        console.log("fetchTemplates pageOffset: ", pageOffset);

        let est_templates;
        let est_templates_rec;

        // if(team_role == 'Leader' || team_role == 'Admin'){
            const team_estimate_templates = await API.graphql({
                query: getTemplatesByTeamID,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: pageOffset
                }
            });
            est_templates = JSON.parse(team_estimate_templates.data.getTemplatesByTeamID).data;
            est_templates_rec = JSON.parse(team_estimate_templates.data.getTemplatesByTeamID).count.count;
        // }
        // else{
        //     const client_estimate_templates = await API.graphql({
        //         query: getTemplatesByClientIDx,
        //         variables: {
        //             user_id: client_id,
        //             doc_type_id: selectedValue,
        //             limit: itemsPerPage,
        //             offset: pageOffset
        //         }
        //     });
        //     est_templates = JSON.parse(client_estimate_templates.data.getTemplatesByClientIDx).data;
        //     est_templates_rec = JSON.parse(client_estimate_templates.data.getTemplatesByClientIDx).count.count;
        // }

        setTotalRecords(est_templates_rec);
        console.log("fetchTemplates estimate_templates>",est_templates);
        console.log("fetchTemplates est_templates_rec>",est_templates_rec);
  
        setTemplates(est_templates);
        setLoading(false);
    }

    async function getADTemplates(){
        console.log('getADTemplates');
        let client_id = JSON.parse(localStorage.getItem('client_data')).client_id;
        const d_temps = await API.graphql({
            query: getAssignDefaultTemplates,
            variables:{
                user_id:client_id
            }
        });

        console.log('getADTemplates', JSON.parse(d_temps.data.getAssignDefaultTemplates));
        setaDTemplates(JSON.parse(d_temps.data.getAssignDefaultTemplates));
    }

    async function fetchAllTemplates() {
        console.log("fetchAllTemplates");
        let client_id = JSON.parse(localStorage.getItem('client_data')).client_id;
        console.log("fetchAllTemplates client_id: ", client_id);
        console.log("fetchAllTemplates selectedValue: ", selectedValue);

        let my_query = (selectedValue == 1) ? getEstimateTemplates : getWOTemplates;

        console.log("fetchAllTemplates my_query: ", my_query);
        const my_templates_result = await API.graphql({
            query: my_query,
            variables: {
                user_id: client_id,
            }
        });

        var my_templates;
        if(selectedValue == 1){
            my_templates = JSON.parse(my_templates_result.data.getEstimateTemplates);
        }
        else{
            my_templates = JSON.parse(my_templates_result.data.getWOTemplates);
        }

        console.log("fetchAllTemplates my_templates_result>",my_templates_result);
        console.log("fetchAllTemplates my_templates>",my_templates);
  
        setAllTemplates(my_templates);
    }

    const handleChangeSelect = e => {
        setSelectedValue(e.value);
        if(e.value == 1){
            navigate('/templates?doc_type_id=1');
        }else{
            navigate('/templates?doc_type_id=2');
        }
        console.log('selectedIndex e.value>',e.value);
        // fetchTemplates();
    }

    function selectType(type){
        setSelectedValue(type);
        console.log('selectType', type);
    }

    const optionsx = [
        { value: 1, label: 'Estimate' },
        { value: 2, label: 'Work Order' }
    ];

    const customeStyles={
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink !important',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
    }

    var page = 'template';

    const validateJSON = (data) => {
        try {
            JSON.parse(data);
        } catch {
            return false;
        }
        return true;
    }


    const handleChangeJSON = e => {
        const reader = new FileReader();
        if(!e.target.files[0])
        {
            setShow(true);
            setUploadError('Please Select a file to upload')
            return false;
        }

        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onload = e => {
            if (!validateJSON(e.target.result)) {
                setShow(true);
                setUploadError('Please select JSON files only!');
                return;
            }
            else{
                // setFiles(e.target.result);
                UploadTemp(e.target.result);
            }
            setLoading(true)
        }
    };


   async function UploadTemp (file)  {

        var client_id = JSON.parse(localStorage.getItem('client_data')).client_id;
        var team_id = JSON.parse(localStorage.getItem('client_data')).team_id;

        const uploadTemplateaa =  await API.graphql({
            query: uploadTemplate,
            variables: {
                user_id: client_id,
                team_id: team_id,
                data:file
            }
        })
        setLoading(false);
        fetchTemplates();
        alert(JSON.parse(uploadTemplateaa.data.uploadTemplate));

    }

    function getEstTemplates(){
        return allTemplates.map((template)=>{
            return {
                label: template.name,
                value: template.template_id
            }
        });
    }

    useEffect(()=>{
        fetchTemplates();
        console.log('useEffect>>>');
        getADTemplates();
        fetchAllTemplates();
    }, [selectedValue,pageOffset]);

    // useEffect(()=>{
    //     fetchAllTemplates();
    //     console.log('useEffect>>>');
    // }, [selectedValue]);


    return (
        <div>
         
             {
                uploadError ?
                <ToastBox
                setShow={setShow}
                show={show}
                Text={uploadError}
                Type={'Error'}
                />
                :null
            }
            <Menu page={page} selectType={selectType}/>
            <ContentContainer>

                <div className="first_left_template">
                    <p className="title">Template Manager</p>

                    <div className="custom-width-select">
                        <label>Template Type</label>
                        <Select
                            placeholder={<div>Select Template</div>}
                            value={optionsx.find(obj => obj.value == selectedValue)}
                            options={optionsx}
                            components={{DropdownIndicator }}
                            onChange={handleChangeSelect}
                            className="w-75 bg-transparent select-shade-items"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    boxShadow: "none",
                                    border:  "1px solid #B4BEBF !important"
                                  }),
                                  menu: (provided, state) => ({
                                    ...provided,
                                    border:"1px solid #B4BEBF",
                                    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.3)",
                                    border:  "1px solid #B4BEBF !important"
                                  }),
                                  placeholder:(provided, state) => ({
                                      ...provided,
                                      color:'#B4BEBF',
                                      fontStyle:'italic'
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused && "#ffffff",
                                    color: "#737F82",
                                 })
                              }}

                        />
                    </div>
                </div>


           <div>
                { loading ?
                    <LoadingContainer>
                        <img className="loader" src={Loader}/>
                    </LoadingContainer>
                :
                    <>
                        { templates.length < 1 ?
                            <>
                                <div className="second_left_template pb-0">
                                    <p className="title">My Templates</p>

                                    <Alert variant="success" style={{opacity:'1', background:'#D3F8EE', fontFamily:'Roboto'}}>
                                        <p style={{fontSize:'14px', fontFamily:'Roboto', color:'#F7941C', fontWeight: 'bold' }}> You don't have any Template. Click on Create New Template button to create.</p>
                                    </Alert>
                                </div>
                            </>
                        :
                            <>
                            <EstimateTemplateTable
                                setPageOffset={setPageOffset}
                                totalRecords={totalRecords}
                                itemsPerPage={itemsPerPage}
                                fetchTemplates={fetchTemplates}
                                templates={templates}
                                estTemplate={getEstTemplates()}
                                loading={loading}
                                setLoading={setLoading}
                                setTemplates={setTemplates}
                                docTypeId={selectedValue}
                                aDTemplates={aDTemplates}
                                setaDTemplates={setaDTemplates}
                                teamRole={teamRole}
                                userID={userID}
                            />
                            </>
                        }
                    </>
                }
           </div>
            
                 { loading ?
                            <LoadingContainer>
                                <img className="loader" src={Loader}/>
                            </LoadingContainer>

                        :
                        <Template>
                        <div className="btn">
                            <Link className="w-100 noDec" to="/newTemplate">Create new Template</Link>
                        </div>

                        <label className="btn">
                             Upload New template
                             <input type="file" className="w-100 noDec" onChange={handleChangeJSON} />
                            {/* <a onClick={UploadTemplateData} className="w-100 noDec">Upload New template</a> */}
                        </label>
                    </Template>


                        }

            </ContentContainer>

            <Footer/>
        </div>
    )
}

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
    }
`

const ContentContainer = styled.div`
    min-height:85vh;
    width:100%;
    background:#F7F9FA;
    padding:0 10%;

    @media(max-width:600px){
                padding: 0 5%;
            }

    .first_left_template{
        width:100%;
        padding:50px 0 0 0;

        .title{
            font-size:28px;
            color:#212E33;
            font-family:Roboto;
            margin:0px 0 33px 0;
            letter-spacing:-1.24px;
        }

        .custom-width-select{
            width:30%;

            @media(max-width:600px){
                width:90%;
            }
        }
    }
    .second_left_template{
        width:100%;
        padding:70px 0 65px 0;

        .title{
            font-size:24px;
            color:#212E33;
            font-family:Roboto;
            margin:0px 0 22px 0;
            letter-spacing:-1.24px;
        }

        @media(max-width:768px){
            padding:30px 0 65px 0;
        }
    }

    .third_left_template{
        width:100%;
        padding:65px 0 18px 0;

        @media(max-width: 768px) {
            padding:20px 0 15px 0;
        }

        .title{
            font-size:24px;
            color:#212E33;
            font-family:Roboto;
            margin:0px 0 15px 0;
            letter-spacing:-1.24px; 

            @media(max-width: 768px) {
                font-size: 20px;
            }
        }
        .desc{
            font-size:14px;
            color:#212E33;
            font-family:Roboto;
            font-weight:500;
            margin-bottom:26px;
        }
    }


`

const Title = styled.div`
    height:20vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;

    p{
        font-size:36px;
        font-weight:500;
    }
`

const TabContent = styled.div`
    height:100px;
    width:100%;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
    padding-left:15px;
    .cTitle{
        font-size:22px;
        font-weight:500;
        text-transform:uppercase;
    }
`

export const Template = styled.div`
    min-height:100px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    padding:75px 3px 277px 0;

    @media(max-width:1080px){
        padding:75px 3px 75px 0;
    }

    @media(max-width:768px){
        padding:25px 0;
        flex-direction:column;
    }
    input[type="file"] {
        display: none;
    }
    .modal-content{
        margin-top: 20px !important;
    }
    .btn{
        width:210px;
        height:44px;
        display:flex;
        align-items:center;
        justify-content:center;
        color:#212E33;
        font-family:Rajdhani;
        margin-top:1rem;
        margin-bottom:1rem;
        text-transform:uppercase;
        border-radius:3px;
        background:#F7941C;
        font-size:16px;
        font-weight:500;
        text-decoration:none;
    }

    .noDec{
        text-decoration:none !important;
        color:#212E33;
    }
`
